const env = process.env.STAGING_ENV || 'staging-02';
export const API_PREFIX = {
  'staging-01': process.env.API_PREFIX || 'https://pos-api-staging-01.chowbus.com/api',
  'staging-02': process.env.API_PREFIX || 'https://pos-api-staging-02.chowbus.com/api',
  'staging-03': 'https://pos-api-staging-03.chowbus.com/api',
  production: 'https://pos-api.chowbus.com/api',
}[env];

export const QRCODE_API_PREFIX = {
  'staging-01': 'https://qrcode-api-staging-01.chowbus.com/api',
  'staging-02': 'https://qrcode-api-staging-02.chowbus.com/api',
  'staging-03': 'https://qrcode-api-staging-03.chowbus.com/api',
  production: 'https://qrcode-api.chowbus.com/api',
}[env];

export const AUTH_API_PREFIX = {
  'staging-01': 'https://auth-staging-01.chowbus.com',
  'staging-02': 'https://auth-staging-02.chowbus.com',
  'staging-03': 'https://auth-staging-03.chowbus.com',
  production: 'https://auth.chowbus.com',
}[env];

export const LOYALTY_API_PREFIX = {
  'staging-01': process.env.LOYALTY_API_PREFIX || 'https://loyalty-api-staging-01.chowbus.com',
  'staging-02': process.env.LOYALTY_API_PREFIX || 'https://loyalty-api-staging-02.chowbus.com',
  'staging-03': process.env.LOYALTY_API_PREFIX || 'https://loyalty-api-staging-03.chowbus.com',
  production: 'https://loyalty-api.chowbus.com',
}[env];
export const LOYALTY_API_PREFIX_SERVERSIDE = {
  'staging-01': 'https://loyalty-api-staging-01.chowbus.com',
  'staging-02': 'https://loyalty-api-staging-02.chowbus.com',
  'staging-03': 'https://loyalty-api-staging-03.chowbus.com',
  production: 'https://loyalty-api.chowbus.com',
}[env];
export const CLIENT_ID = {
  'staging-01': 'x7aI8j4ePLMWWcx3Tji2k-VxeWgCvcD6CBkw4F7-ZLc',
  'staging-02': 'pos_client_id',
  'staging-03': 'x7aI8j4ePLMWWcx3Tji2k-VxeWgCvcD6CBkw4F7-ZLc',
  production: 'f6b0e6bdc1f07db8bef906e9ca769e2e4b65b3fb049821bf3f95b3691a9e1060',
}[env];
export const MONOLITH_API_PREFIX = {
  'staging-01': process.env.MONOLITH_API_PREFIX || 'https://chowbus-staging-01.chowbus.com',
  'staging-02': process.env.MONOLITH_API_PREFIX || 'https://chowbus-staging-02.chowbus.com',
  'staging-03': 'https://chowbus-staging-03.chowbus.com',
  production: 'https://api.chowbus.com',
}[env];
export const PREPAIDCARD_API_PREFIX = MONOLITH_API_PREFIX;
export const GIFTCARD_API_PREFIX = MONOLITH_API_PREFIX;
export const SALES_REPORT_API_PREFIX = {
  'staging-01': 'https://api-staging-01.chowbus.com/restaurant-report/api',
  'staging-02': 'https://api-staging-02.chowbus.com/restaurant-report/api',
  'staging-03': 'https://api-staging-03.chowbus.com/restaurant-report/api',
  production: 'https://api.chowbus.com/restaurant-report/api',
}[env];

export const MONOLITH_RESTAURANT_API_PREFIX = {
  'staging-01': 'https://api-staging-01.chowbus.com',
  'staging-02': 'https://api-staging-02.chowbus.com',
  'staging-03': 'https://api-staging-03.chowbus.com',
  production: 'https://api.chowbus.com',
}[env];

export const INTERNAL_SALES_REPORT_API_PREFIX = {
  'staging-01': 'https://restaurant-report-staging-01.chowbus.com/api',
  'staging-02': 'https://restaurant-report-staging-02.chowbus.com/api',
  'staging-03': 'https://restaurant-report-staging-03.chowbus.com/api',
  production: 'https://restaurant-report.chowbus.com/api',
}[env];

export const POS_LOGGING_API_PREFIX = {
  'staging-01': 'https://api-staging-01.chowbus.com/pos/logging',
  'staging-02': 'https://api-staging-02.chowbus.com/pos/logging',
  'staging-03': 'https://api-staging-03.chowbus.com/pos/logging',
  production: 'https://api.chowbus.com/pos/logging',
}[env];

export const CDS_API_PREFIX = {
  'staging-01': 'https://api-staging-01.chowbus.com/cds/api',
  'staging-02': 'https://api-staging-02.chowbus.com/cds/api',
  'staging-03': 'https://api-staging-03.chowbus.com/cds/api',
  production: 'https://api.chowbus.com/cds/api',
}[env];

export const LOCALE_EN = 'en';
export const LOCALE_ZH = 'zh';

export const DEVICE_TYPE_ENUMS = {
  TERMINAL: 'terminal',
  PRINTER: 'printer',
  READER: 'reader',
  KIOSK: 'kiosk',
  CUSTOMER_FACING_DISPLAY: 'customer_facing_display',
  WAITLIST_TERMINAL: 'waitlist_terminal',
  TABLE_ORDERING: 'tablet_ordering',
};

export const DEVICE_TYPES = {
  [DEVICE_TYPE_ENUMS.TERMINAL]: {
    name: 'Terminal',
    foreign_name: '终端',
  },
  [DEVICE_TYPE_ENUMS.PRINTER]: {
    name: 'Printer',
    foreign_name: '打印机',
  },
  [DEVICE_TYPE_ENUMS.READER]: {
    name: 'Reader',
    foreign_name: '读卡器',
  },
  [DEVICE_TYPE_ENUMS.KIOSK]: {
    name: 'Kiosk',
    foreign_name: '自助点餐机',
  },
  [DEVICE_TYPE_ENUMS.CUSTOMER_FACING_DISPLAY]: {
    name: 'Customer Facing Display',
    foreign_name: '客显屏',
  },
  [DEVICE_TYPE_ENUMS.WAITLIST_TERMINAL]: {
    name: 'Waitlist Terminal',
    foreign_name: '等位终端',
  },
  [DEVICE_TYPE_ENUMS.TABLE_ORDERING]: {
    name: 'Tablet Ordering',
    foreign_name: '点餐平板',
  },
};

export const VERIFY_BY_EMAIL = 'email';
export const VERIFY_BY_SMS = 'sms';

export const DEVICE_STATUS_ENUMS = {
  ACTIVE: 'active',
  INACTIVE: 'deactive',
};

export const DISCOUNT_TYPE_FIXED_DISCOUNT = 'fixed_discount';
export const DISCOUNT_TYPE_OPEN_DISCOUNT = 'open_discount';
export const DISCOUNT_TYPE_PERCENTAGE = 'percentage';
export const DISCOUNT_TYPE_FIXED_AMOUNT = 'fixed_amount';

export const STATUS_ACTIVE = 'active';
export const STATUS_PAID = 'paid';

export const REFUND_STATUS_ENUMS = {
  NO_REFUNED: 'no_refunded',
  PARTIAL_REFUNDED: 'partial_refunded',
  REFUNDED: 'refunded',
};

export const PAYMENT_STATUS_ENUMS = {
  REFUNDED: 'refunded',
  FAILED: 'failed',
  VOIDED: 'voided',
  PAID: 'paid',
};

export const ORDER_STATUS_ENUMS = {
  CANCELED: 'Canceled',
  VOIDED: 'Voided',
  PAID: PAYMENT_STATUS_ENUMS.PAID,
  REFUNDED: REFUND_STATUS_ENUMS.REFUNDED,
  PARTIAL_REFUNDED: REFUND_STATUS_ENUMS.PARTIAL_REFUNDED,
};

const SHOPPING_CART_STATUS_ENUMS = {
  SAVED: 'Saved',
  IN_PROGRESS: 'In Progress',
  PARTIAL_PAID: 'Partial Paid',
  PENDING_PAYMENT: 'Pending Payment',
  PAID: 'Paid',
};

export const DISCOUNT_TYPE = {
  [DISCOUNT_TYPE_FIXED_DISCOUNT]: {
    name: 'Fixed Discount',
    foreign_name: '固定折扣',
  },
  [DISCOUNT_TYPE_OPEN_DISCOUNT]: {
    name: 'Open Discount',
    foreign_name: '自定义折扣',
  },
};

export const DISCOUNT_TYPES = {
  [DISCOUNT_TYPE_PERCENTAGE]: {
    name: 'Percentage',
    foreign_name: '百分比',
  },
  [DISCOUNT_TYPE_FIXED_AMOUNT]: {
    name: 'Fixed Amount',
    foreign_name: '固定金额',
  },
};

export const DISCOUNT_TYPES_OPTIONS = Object.keys(DISCOUNT_TYPE).map((type) => ({
  ...DISCOUNT_TYPE[type],
  id: type,
}));

export const DISCOUNT_TYPE_OPTIONS = Object.keys(DISCOUNT_TYPES).map((type) => ({
  ...DISCOUNT_TYPES[type],
  id: type,
}));

export const COMPLETED_ORDER_STATUS_MAP = {
  [ORDER_STATUS_ENUMS.PAID]: {
    name: 'Paid',
    foreign_name: '已付款',
  },
  [ORDER_STATUS_ENUMS.REFUNDED]: {
    name: 'Refunded',
    foreign_name: '已退款',
  },
  [ORDER_STATUS_ENUMS.PARTIAL_REFUNDED]: {
    name: 'Partial Refunded',
    foreign_name: '部分退款',
  },
  [ORDER_STATUS_ENUMS.VOIDED]: {
    name: 'Voided',
    foreign_name: '已作废',
  },
};

export const OPEN_ORDER_STATUS_MAP = {
  [SHOPPING_CART_STATUS_ENUMS.SHOPPING_CART_STATUS_ENUMS]: {
    name: 'Saved',
    foreign_name: '已保存',
  },
  [SHOPPING_CART_STATUS_ENUMS.IN_PROGRESS]: {
    name: 'In Progress',
    foreign_name: '处理中',
  },
  [SHOPPING_CART_STATUS_ENUMS.PARTIAL_PAID]: {
    name: 'Partial Paid',
    foreign_name: '部分支付',
  },
  [SHOPPING_CART_STATUS_ENUMS.PENDING_PAYMENT]: {
    name: 'Pending Payment',
    foreign_name: '待支付',
  },
  [SHOPPING_CART_STATUS_ENUMS.PAID]: {
    name: 'Paid',
    foreign_name: '已付款',
  },
};

export const OPEN_ORDER_STATUSES = Object.keys(OPEN_ORDER_STATUS_MAP).map((status) => ({
  ...OPEN_ORDER_STATUS_MAP[status],
  type: status,
}));

export const SERVICE_TYPE_ENUMS = {
  DINE_IN: 'dine_in',
  TAKE_OUT: 'take_out',
  DELIVERY: 'delivery',
  PREPAID: 'prepaid',
  CREDIT_CARD_TERMINAL: 'other',
};
export const SERVICE_TYPES = [
  {
    type: SERVICE_TYPE_ENUMS.DINE_IN,
    name: 'Dine In',
    foreign_name: '堂食',
  },
  {
    type: SERVICE_TYPE_ENUMS.TAKE_OUT,
    name: 'Take Out',
    foreign_name: '打包',
  },
  {
    type: SERVICE_TYPE_ENUMS.DELIVERY,
    name: 'Delivery',
    foreign_name: '递送',
  },
  {
    type: SERVICE_TYPE_ENUMS.CREDIT_CARD_TERMINAL,
    name: 'Other',
    foreign_name: '其他',
  },
];
export const SERVICE_TYPE_MAPPING = SERVICE_TYPES.reduce((prev, current) => {
  prev[current.type] = current;
  return prev;
}, {});

export const ORDER_SOURCE_ENUMS = {
  WALK_IN: 'walk_in',
  QR_CODE: 'qr_code',
  CALL_IN: 'call_in',
  ONLINE: 'online',
  EXTERNAL: 'external',
  KIOSK: 'kiosk',
  CREDIT_CARD_TERMINAL: 'payment_terminal',
  LOYALTY: 'loyalty',
  ONLINE_ORDERING: 'online_ordering',
};

export const PIN_SERVICE_TYPES = [
  {
    type: SERVICE_TYPE_ENUMS.DINE_IN,
    name: 'Dine In',
    foreign_name: '堂食',
  },
  {
    type: SERVICE_TYPE_ENUMS.TAKE_OUT,
    name: 'Take Out',
    foreign_name: '打包',
  },
  {
    type: ORDER_SOURCE_ENUMS.CALL_IN,
    name: 'Call In',
    foreign_name: '电话订餐',
  },
];

export const TIPPING_CALL_IN_SERVICE_TYPE_ENUMS = {
  CALL_IN_DELIVERY: 'call_in_delivery',
  CALL_IN_TAKE_OUT: 'call_in_take_out',
};

export const TIPPING_SERVICE_TYPES = [
  {
    type: SERVICE_TYPE_ENUMS.DINE_IN,
    name: 'Dine In',
    foreign_name: '堂食',
  },
  {
    type: SERVICE_TYPE_ENUMS.TAKE_OUT,
    name: 'Take Out',
    foreign_name: '打包',
  },
  {
    type: TIPPING_CALL_IN_SERVICE_TYPE_ENUMS.CALL_IN_DELIVERY,
    name: 'Call In-Delivery',
    foreign_name: '电话订单-递送',
  },
  {
    type: TIPPING_CALL_IN_SERVICE_TYPE_ENUMS.CALL_IN_TAKE_OUT,
    name: 'Call In-Take Out',
    foreign_name: '电话订单-打包',
  },
];

export const IN_HOUSE_ORDER_SOURCES = [
  ORDER_SOURCE_ENUMS.WALK_IN,
  ORDER_SOURCE_ENUMS.CALL_IN,
  ORDER_SOURCE_ENUMS.QR_CODE,
  ORDER_SOURCE_ENUMS.KIOSK,
  ORDER_SOURCE_ENUMS.CREDIT_CARD_TERMINAL,
  ORDER_SOURCE_ENUMS.EXTERNAL,
  ORDER_SOURCE_ENUMS.LOYALTY,
];
export const ONLINE_ORDER_SOURCES = [ORDER_SOURCE_ENUMS.ONLINE];
export const ALL_ORDER_SOURCES = [...IN_HOUSE_ORDER_SOURCES, ...ONLINE_ORDER_SOURCES];

export const ORDER_SOURCES_MAPPING = {
  [ORDER_SOURCE_ENUMS.WALK_IN]: {
    name: 'Walk In',
    foreign_name: '到店点餐',
  },
  [ORDER_SOURCE_ENUMS.CALL_IN]: {
    name: 'Call In',
    foreign_name: '电话订餐',
  },
  [ORDER_SOURCE_ENUMS.QR_CODE]: {
    name: 'QR Code',
    foreign_name: '扫码点餐',
  },
  [ORDER_SOURCE_ENUMS.KIOSK]: {
    name: 'Kiosk',
    foreign_name: '自助点餐机',
  },
  [ORDER_SOURCE_ENUMS.ONLINE]: {
    name: 'Online',
    foreign_name: '线上订餐',
  },
  [ORDER_SOURCE_ENUMS.CREDIT_CARD_TERMINAL]: {
    name: 'Terminal',
    foreign_name: '支付终端',
  },
  [ORDER_SOURCE_ENUMS.EXTERNAL]: {
    name: 'External',
    foreign_name: '外部系统',
  },
  [ORDER_SOURCE_ENUMS.LOYALTY]: {
    name: 'Loyalty',
    foreign_name: '会员系统',
  },
};

export const ORDER_SOURCES = Object.keys(ORDER_SOURCES_MAPPING).map((key) => ({
  type: key,
  ...ORDER_SOURCES_MAPPING[key],
}));

export const PAYMENT_STATUS_MAP = {
  [PAYMENT_STATUS_ENUMS.PAID]: {
    name: 'Paid',
    foreign_name: '已支付',
  },
  authorized: {
    name: 'Authorized',
    foreign_name: '已授权',
  },
  tipping: {
    name: 'Tipping',
    foreign_name: '小费清算中',
  },
  [PAYMENT_STATUS_ENUMS.FAILED]: {
    name: 'Failed',
    foreign_name: '支付失败',
  },
};

export const REFUND_STATUS_MAP = {
  [REFUND_STATUS_ENUMS.NO_REFUNED]: {
    name: 'N/A',
    foreign_name: 'N/A',
  },
  [REFUND_STATUS_ENUMS.PARTIAL_REFUNDED]: {
    name: 'Partial Refunded',
    foreign_name: '部分已退款',
  },
  [REFUND_STATUS_ENUMS.REFUNDED]: {
    name: 'Refunded',
    foreign_name: '已退款',
  },
};

export const SETTLEMENT_STATUS_MAP = {
  '': {
    name: 'Wait For Settlement',
    foreign_name: '等待结算',
  },
  succeeded: {
    name: 'Settled Successfully',
    foreign_name: '已结算',
  },
  failed: {
    name: 'Settled Failed',
    foreign_name: '结算失败',
  },
};

export const PAYMENT_STATUSES = Object.keys(PAYMENT_STATUS_MAP).map((status) => ({
  type: status,
  ...PAYMENT_STATUS_MAP[status],
}));

export const REFUND_STATUSES = Object.keys(REFUND_STATUS_MAP).map((status) => ({
  type: status,
  ...REFUND_STATUS_MAP[status],
}));

export const PERMISSION_TYPES = [
  { name: 'Menu', foreign_name: '菜单', value: 'menu' },
  { name: 'Action', foreign_name: '操作', value: 'operation' },
];

export const PAYMENT_TYPE_CARD_PRESENT = 'card_present';
export const PAYMENT_TYPE_CARD = 'card';
export const PAYMENT_TYPE_CASH = 'cash';
export const PAYMENT_TYPE_VOUCHER = 'voucher';
export const PAYMENT_TYPE_CASHBACK = 'cashback';
export const PAYMENT_TYPE_LOYALTY_CREDIT = 'loyalty_credit';
export const PAYMENT_TYPE_COMPENSATION = 'compensation';
export const PAYMENT_TYPE_OTHER = 'other';
export const PAYMENT_TYPE_OTHERS = 'others';
export const PAYMENT_TYPE_ONLINE = 'online';
export const COMMON_PAYMENT_TYPES = [PAYMENT_TYPE_CARD_PRESENT, PAYMENT_TYPE_CARD, PAYMENT_TYPE_CASH];
export const TRANSACTION_COMPENSATION = {
  name: 'Comps',
  foreign_name: '补偿',
};

export const ACTIVITY_TYPE_OPEN_CASH_BOX = 'open_cash_box';

export const ACTIVITY_TYPE_MAP = {
  [ACTIVITY_TYPE_OPEN_CASH_BOX]: {
    name: 'Open Cash Box',
    foreign_name: '开钱箱',
  },
};

export const PURPOSE_FOR_CASH_BOX = {
  home_page: {
    name: 'Home Page',
    foreign_name: '主页打开钱箱',
  },
  cash_refund: {
    name: 'Cash Refund',
    foreign_name: '现金退款',
  },
  cash: {
    name: 'Cash',
    foreign_name: '现金收款/结账',
  },
};

export const TABLE_WIDGET = 'table';
export const OBJECT_WIDGET = 'obstacle';
export const SHAPE_TYPE_ROUND = 'round';
export const SHAPE_TYPE_RECTANGLE = 'rectangle';

export const GREETINGS = {
  Night: {
    en: 'Good Night',
    zh: '晚上好',
  },
  Morning: {
    en: 'Good Morning',
    zh: '早上好',
  },
  Noon: {
    en: 'Good Noon',
    zh: '中午好',
  },
  Afternoon: {
    en: 'Good Afternoon',
    zh: '下午好',
  },
  Evening: {
    en: 'Good Evening',
    zh: '晚上好',
  },
};

export const DND_TYPE_DISH_CATEGORY = 'dish_category';
export const DND_TYPE_DISH_ITEM = 'dish_item';
export const DND_TYPE_FLOOR_PLAN_SECTION = 'floor_plan_section';
export const DND_TYPE_TABLE = 'table';
export const DND_TYPE_DISH_PREFERENCE = 'dish_preference';
export const DND_TYPE_DISH_PREFERENCE_OPTION = 'dish_preference_option';
export const DND_TYPE_CUSTOMIZATION_GROUP = 'customization_group';
export const DND_TYPE_CUSTOMIZATION_OPTION = 'customization_option';
export const DND_TYPE_MAJOR_INDEX = 'major_index';
export const DND_TYPE_CONVERSION_INDEX = 'conversion_index';
export const DND_TYPE_SALES_SECTION = 'sales_section';
export const DND_TYPE_WAITLIST_TABLE_QUEUE_OPTION = 'waitlist_table_queue_option';
export const DND_TYPE_RESETATION_TABLE_SETTING = 'reservation_table_setting';
export const FEE_TYPE_PERCENTAGE = 'percentage';
export const purposesToEnableConfigPrinterSetting = ['label', 'kitchen'];
export const IN_HOUSE_SERVICE = 'in-house';
export const CHOWBUS_SERVICE = 'online';
export const ALL_SERVICE = 'all';

export const TABLE_SECTION = 'table_section';
export const REVENUE_CENTER = 'revenue_center';

export const DISTRIBUTION_MODES = {
  POS: 'pos',
  SMART_ORDERING: 'smart_ordering',
  THIRD_PARTY: 'third_party',
  WHITE_LABEL: 'white_label',
  GIFT_CARD: 'gift_card',
  LOYALTY_CRM: 'crm',
  WAIT_LIST: 'wait_list',
  LOYALTY: 'loyalty',
  RESERVATION: 'reservation',
  PROMOTION: 'promotion',
  CPS: 'cps',
};
export const IN_HOUSE_DISTRIBUTION_MODES = [DISTRIBUTION_MODES.POS, DISTRIBUTION_MODES.SMART_ORDERING];
export const CHOWBUS_DISTRIBUTION_MODES = [DISTRIBUTION_MODES.THIRD_PARTY, DISTRIBUTION_MODES.WHITE_LABEL];

export const MENU_SOURCES_MAPPING = {
  [IN_HOUSE_SERVICE]: {
    name: 'In-House Menus',
    foreign_name: '堂食菜单',
    distribution_modes: IN_HOUSE_DISTRIBUTION_MODES,
  },
  [CHOWBUS_SERVICE]: {
    name: 'Online Menus',
    foreign_name: '线上菜单',
    distribution_modes: CHOWBUS_DISTRIBUTION_MODES,
  },
};

export const SERVICE_NAME_MAPPING = {
  [IN_HOUSE_SERVICE]: {
    name: 'In-House',
    foreign_name: '堂食',
  },
  [CHOWBUS_SERVICE]: {
    name: 'Chowbus',
    foreign_name: '线上',
  },
};

export const CHOWBUS_MENU_NAME_MAPPING = {
  [DISTRIBUTION_MODES.SMART_ORDERING]: {
    name: 'QR code & kiosk',
    foreign_name: '扫码点餐 & 自助点餐机',
  },
  [DISTRIBUTION_MODES.THIRD_PARTY]: {
    name: 'Third Party',
    foreign_name: '第三方平台',
  },
  [DISTRIBUTION_MODES.WHITE_LABEL]: {
    name: 'Online Ordering',
    foreign_name: '餐厅网站',
  },
  [DISTRIBUTION_MODES.POS]: {
    name: 'POS',
    foreign_name: 'POS',
  },
};

export const MENU_SOURCES = Object.keys(MENU_SOURCES_MAPPING).map((key) => ({
  service_type: key,
  ...MENU_SOURCES_MAPPING[key],
}));

// export const QR_CODE_SIZE_STAND = 'A5_stand';
export const QR_CODE_SIZE_STICKER = 'sticker';

export const QR_CODE_SIZE_STAND_A5 = 'A5_stand';
export const QR_CODE_SIZE_STAND_5X8_INCH = '5X8_inch_stand';
export const QR_CODE_SIZE_STICKER_4X6_INCH = '4x6_inch_sticker';
export const QR_CODE_SIZE_STICKER_325_INCH = '325X325_inch_sticker';
export const QR_CODE_SIZE_ACRYLIC_SHEETS_4X4_INCH = '4x4_inch_sticker';
export const QR_CODE_SIZE_ACRYLIC_SHEETS_250X250_INCH = '250x250_inch_acrylic';

export const NORMAL_QRCODE_TYPE = 'normal_qrcode';
export const TAKEOUT_QRCODE_TYPE = 'takout_qrcode';
export const MENUBROWSING_QRCODE_TYPE = 'menu_browsing_qrcode';

export const FORMAT_WITH_HHMMSS = 'MM-DD-YYYYTHH:mm:ss';
export const GLOBAL_HEADER_HEIGHT = 64;

export const CUSTOM_ALTERNATIVE_PAYMENT = {
  name: 'Customized Alternative Payment',
  foreign_name: '自定义支付方式',
  payment_type: PAYMENT_TYPE_OTHER,
};

export const PLATFORM_WEBSITE = 'Website';

export const ALL_PLATFORMS = [
  'Chowbus',
  'Caviar',
  'Doordash',
  'Grubhub',
  'Postmates',
  'Uber Eats',
  'Fantuan',
  'HungryPanda',
  'Ricepo',
  'Other',
];

export const ALL_SELF_DELIVERY_PLATFORMS = [
  'Chowbus',
  'Caviar',
  'Doordash',
  'Grubhub',
  'Postmates',
  'Uber Eats',
  'Fantuan',
  'HungryPanda',
  'Ricepo',
  PLATFORM_WEBSITE,
  'Other',
];

export const SALES_OPTIONS = [
  {
    id: IN_HOUSE_SERVICE,
    name: 'In-house',
    foreign_name: '堂食销售',
    orderSources: IN_HOUSE_ORDER_SOURCES,
  },
  {
    id: CHOWBUS_SERVICE,
    name: 'Online',
    foreign_name: '线上销售',
    orderSources: ONLINE_ORDER_SOURCES,
  },
  {
    id: ALL_SERVICE,
    name: 'All Sales',
    foreign_name: '所有销售',
    orderSources: ALL_ORDER_SOURCES,
  },
];

export const CUSTOMIZATION_DISPLAY_LAYOUT = {
  FULL_VIEW: 'full',
  COMPACT_VIEW: 'compact',
};

export const COLOR_PALETTE = [
  '#E00051',
  '#FE9C22',
  '#15D0C5',
  '#D34CB6',
  '#8CD319',
  '#6BAAE4',
  '#F76161',
  '#00E0FF',
  '#19D342',
  '#9360FF',
  '#FFE600',
  '#FF7A00',
];

export const UnassignedDepartment = {
  name: 'Unassigned',
  foreign_name: '未分配',
  value: undefined,
};

export const GIFT_CARD_TYPE_RG = 'RG';
export const GIFT_CARD_TYPE_UN_RG = 'Non-RG';
export const GIFT_CARD_SUSPEND = 'suspended';
export const GIFT_CARD_ACTIVED = 'active';
export const GIFT_CARD_STATUS_MAPPING = {
  [GIFT_CARD_SUSPEND]: {
    name: 'Suspended',
    foreign_name: '暂停',
  },
  [GIFT_CARD_ACTIVED]: {
    name: 'Active',
    foreign_name: '有效',
  },
};

export const GIFT_CARD_TYPE_MAPPING = {
  [GIFT_CARD_TYPE_RG]: {
    name: 'RG',
    foreign_name: '连锁',
  },
  [GIFT_CARD_TYPE_UN_RG]: {
    name: 'Non-RG',
    foreign_name: '单店',
  },
};

export const GIFT_CARD_TRANSACTION_TYPE_ADD_VALUE = 'recharge';
export const GIFT_CARD_TRANSACTION_TYPE_PURCHANSE = 'consume';
export const GIFT_CARD_TRANSACTION_TYPE_REFUNDED = 'refunded';
export const GIFT_CARD_TRANSACTION_TYPE_CANCELED = 'canceled';

export const GIFT_CARD_TRANSACTION_TYPE_MAPPING = {
  [GIFT_CARD_TRANSACTION_TYPE_ADD_VALUE]: {
    name: 'Add Value',
    foreign_name: '充值',
  },
  [GIFT_CARD_TRANSACTION_TYPE_PURCHANSE]: {
    name: 'Purchase',
    foreign_name: '消费',
  },
  [GIFT_CARD_TRANSACTION_TYPE_REFUNDED]: {
    name: 'Refund',
    foreign_name: '退款',
  },
  [GIFT_CARD_TRANSACTION_TYPE_CANCELED]: {
    name: 'Canceled',
    foreign_name: '取消',
  },
};

export const FORMAT_TIMES = {
  DATE: 'YYYY-MM-DD',
  MDY: 'MM/DD/YYYY',
};

export const LOYALTY_CAMPAIGN_STATUS_ENUMS = {
  ACTIVE: 1,
  INACTIVE: 0,
};
export const LOYALTY_MANUAL_CAMPAIGN_STATUS_ENUMS = {
  ACTIVE: 'draft',
  INACTIVE: 'reviewed',
};

export const LOYALTY_CAMPAIGN_TYPE_ENUMS = {
  SMS: 'sms',
  MMS: 'mms',
};

export const LOYALTY_CAMPAIGN_STATUS_MAP = {
  [LOYALTY_CAMPAIGN_STATUS_ENUMS.ACTIVE]: {
    name: 'Active',
    foreign_name: '激活',
  },
  [LOYALTY_CAMPAIGN_STATUS_ENUMS.INACTIVE]: {
    name: 'Inactive',
    foreign_name: '未激活',
  },
};

export const LOYALTY_MANUAL_CAMPAIGN_STATUS_MAP = {
  [LOYALTY_MANUAL_CAMPAIGN_STATUS_ENUMS.ACTIVE]: {
    name: 'Active',
    foreign_name: '激活',
  },
  [LOYALTY_MANUAL_CAMPAIGN_STATUS_ENUMS.INACTIVE]: {
    name: 'Inactive',
    foreign_name: '未激活',
  },
};

export const LOYALTY_CAMPAIGN_TYPE_MAP = {
  [LOYALTY_CAMPAIGN_TYPE_ENUMS.SMS]: {
    name: 'SMS',
    foreign_name: '短信',
  },
  [LOYALTY_CAMPAIGN_TYPE_ENUMS.MMS]: {
    name: 'MMS',
    foreign_name: '彩信',
  },
};

export const LOYALTY_CAMPAIGN_STATUS = Object.keys(LOYALTY_CAMPAIGN_STATUS_MAP).map((status) => ({
  type: status,
  ...LOYALTY_CAMPAIGN_STATUS_MAP[status],
}));

export const LOYALTY_CAMPAIGN_TYPE = Object.keys(LOYALTY_CAMPAIGN_TYPE_MAP).map((status) => ({
  type: status,
  ...LOYALTY_CAMPAIGN_TYPE_MAP[status],
}));

export const LOYALTY_MANUAL_CAMPAIGN_STATUS = Object.keys(LOYALTY_MANUAL_CAMPAIGN_STATUS_MAP).map((status) => ({
  type: status,
  ...LOYALTY_MANUAL_CAMPAIGN_STATUS_MAP[status],
}));

export const SALES_CATEGOTY_CONFIG_MODE = {
  CATEGORY: 'category',
  MEAL_PROTOTYPE: 'meal_prototype',
};

export const SEND_SERVICE_TYPES = [
  {
    type: SERVICE_TYPE_ENUMS.DINE_IN,
    name: 'Dine In',
    foreign_name: '堂食',
  },
  {
    type: SERVICE_TYPE_ENUMS.TAKE_OUT,
    name: 'Take Out',
    foreign_name: '打包',
  },
  {
    type: SERVICE_TYPE_ENUMS.DELIVERY,
    name: 'Delivery',
    foreign_name: '递送',
  },
];
export const TEMP_ID_PREFIX = 'temp_';
